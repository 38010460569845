<template>
  <div class="flex pb-4 items-center bg-transparent">
    <!-- Dropdowns and Submit button -->
    <div class="px-4 w-3/5">
      <p>Dedicated Service Agreements</p>
      <select class="w-full shadow form-select appearance-none
                    block px-3 py-1.5 text-base font-normal
                    text-gray-700 bg-clip-padding bg-no-repeat
                    border border-solid border-gray-300 rounded transition
                    ease-in-out m-0 focus:text-gray-700 focus:bg-white
                    focus:border-cyan-600 focus:outline-none"
              v-model="selectedContract"
              :disabled="!isAuthenticated"
              :class="{'bg-gray-200': !isAuthenticated, 'bg-': isAuthenticated}">
        <option disabled value="">Select Company</option>
        <option v-for="contract in contracts" :key="contract.company.id" :value="contract.id">
          {{ contract.company.name }}
        </option>
      </select>
    </div>

    <div class="px-5 w-1/5">
      <p>Report Frequency</p>
      <select class="w-full shadow form-select appearance-none
                    block px-3 py-1.5 text-base font-normal
                    text-gray-700 bg-clip-padding bg-no-repeat
                    border border-solid border-gray-300 rounded transition
                    ease-in-out m-0 focus:text-gray-700 focus:bg-white
                    focus:border-cyan-600 focus:outline-none"
              v-model="selectedFrequency"
              :disabled="!isAuthenticated"
              :class="{'bg-gray-200': !isAuthenticated, 'bg-white': isAuthenticated}">
        <option disabled value="">Select Frequency</option>
        <option v-for="frequency in frequencies" :key="frequency.id" :value="frequency.id">
          {{ frequency.label }}
        </option>
      </select>
    </div>

    <div class="px-5 w-1/5">
      <p>Update Frequency</p>
      <button class="w-full shadow px-4 py-2 text-white rounded transition-colors"
              :class="{'bg-gray-500': isSubmitDisabled, 'bg-cyan-700 hover:bg-cyan-500': !isSubmitDisabled}"
              :disabled="isSubmitDisabled"
              @click="submit">
        SUBMIT
      </button>
    </div>
  </div>
</template>

<script>
import { useMyStore } from '../pinia/store';
import { useAuthStore } from '../pinia/authStore';
import { onMounted, computed, watch } from 'vue';
import axios from 'axios';

export default {
  computed: {
    contracts() {
      const myStore = useMyStore();
      return myStore.contracts;
    },
    frequencies() {
      const myStore = useMyStore();
      return myStore.frequencies;
    },
    selectedContract: {
      get() {
        const myStore = useMyStore();
        return myStore.selectedContract;
      },
      set(value) {
        const myStore = useMyStore();
        myStore.selectContract(value);
      }
    },
    selectedFrequency: {
      get() {
        const myStore = useMyStore();
        return myStore.selectedFrequency;
      },
      set(value) {
        const myStore = useMyStore();
        myStore.selectFrequency(value);
      }
    },
    isSubmitDisabled() {
      const myStore = useMyStore();
      const authStore = useAuthStore();
      return !myStore.selectedFrequency || !authStore.isAuthenticated;
    },
  },
  setup() {
    const authStore = useAuthStore();
    const myStore = useMyStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);

    const fetchContracts = async () => {
      if (authStore.isAuthenticated) {
        await myStore.fetchContracts();
      }
    };

    // Fetch contracts when the component is first mounted
    onMounted(fetchContracts);

    // Reactively fetch contracts when the authentication state changes
    watch(() => authStore.isAuthenticated, fetchContracts);

    watch(() => myStore.selectedContract, (newContractId) => {
      const selectedContract = myStore.contracts.find(contract => contract.id === newContractId);
      if (selectedContract) {
        myStore.changeCompany();
        console.log(`Selected Company: ${myStore.lastReport.company}, newContractId: ${newContractId}`);
        if (myStore.lastReport.company !== newContractId) {
          if (selectedContract.frequency) {
            myStore.selectFrequency(selectedContract.frequency.value);
          }
        }
      }
    }, { immediate: true });


    return {
      isAuthenticated,
    };
  },
  methods: {
    async updateContractFrequency(contractId, frequency) {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      if (!authStore.token) {
        throw new Error('Authentication token is not available.');
      }

      const payload = {
        frequency: frequency,
      };

      try {
        const response = await axios.post(`${apiUrl}/accelodsa/contracts/${contractId}/frequency`, payload, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status >= 200 && response.status < 300) {
          console.log('Frequency updated successfully:', response);
        } else {
          console.error('Failed to update frequency:', response);
        }
      } catch (error) {
        console.error('Error updating frequency:', error);
      }
    },
    async submit() {
      const myStore = useMyStore();
      const selectedContract = myStore.contracts.find(contract => contract.id === myStore.selectedContract);
      if (selectedContract) {
        try {
          await this.updateContractFrequency(myStore.selectedContract, myStore.selectedFrequency);
          console.log(`Updated frequency for contract ${selectedContract.id} to ${myStore.selectedFrequency}`);
        } catch (error) {
          console.error('Failed to update frequency:', error);
        }
      }
    },
  },
};
</script>
